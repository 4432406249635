// Execute the real blazer api call
window.hijackedBlazerApiCall = function(params) {
  const { payload, divId, chartType, options, project_data } = params;

  $.ajax({
    type: "GET",
    url: "/get_query_response",
    data: {
      payload: payload,
      url: process.env.BLAZER_APP_API_URL
    },
    success(data) {
      if (data.converted_data && data.converted_data[0] == 'image'){
        $("#"+divId).html('<img src="'+ data.converted_data[1] +'" />')
      } else {
        plotChart(data, chartType, divId, options, project_data);
      }
    },
    error(data) {
      console.log("=====Error in query====");
      console.log(data);
      console.log("=====Error in query====");
    }
  });
}

// Options for the Intersection Observer
window.intersectionConfig = {
  // Add root here so rootBounds in entry object is not null
  root: document.body,
  // Callback will be fired when 1% of element is visible
  threshold: [0.01]
};

// If api was called, remove observer
window.removeIntersectionObserver = function (observer, divId) {
  observer.unobserve(document.getElementById(divId));
}

// Watch the position of the chart container
// If in view point, call blazer API and remove observer
window.handleIntersectionObserver = function (params) {
  const { divId } = params;

  let observer = new IntersectionObserver(function(entries) {
    if(entries[0].isIntersecting === true) {
      hijackedBlazerApiCall(params)
      removeIntersectionObserver(observer, divId)
    }
  }, intersectionConfig);

  observer.observe(document.getElementById(divId));
}

// Instead of calling blazer right away, initiate an observer
// Whereby if container divId comes into view, then execute the fetch request
window.blazerApiCall = function(payload, divId, chartType, options, project_data = {}) {
  const params = { payload, divId, chartType, options, project_data }
  if(divId === '' || divId === 'category_sparkline_' || divId === 'sub_category_sparkline_' || divId === 'question_sparkline_') {
    hijackedBlazerApiCall(params)
  } else{
    handleIntersectionObserver(params)
  }
}

window.prophetApiCall = function(payload) {
  return new Promise(function (resolve, reject) {
    $.ajax({
      type: "GET",
      url: "/get_query_response",
      data: {
        payload: payload,
        url: process.env.BLAZER_APP_ANOMALY_API_URL
      }
    }).then(
      (response) => {
        console.log('Processing Prophet Request');
        resolve(response);
      },
      (error) => {
        console.log('Error in Prophet Request');
        reject([]);
      }
    );
  });
}

window.postApiCall = function(payload) {
  return new Promise(function (resolve, reject) {
    $.ajax({
      type: "GET",
      url: "/get_query_response",
      data: {
        payload: payload,
        url: process.env.BLAZER_APP_API_URL
      }
    }).then(
        (response) => {
          console.log('Processing Request');
          resolve(response);
        },
        (error) => {
          console.log("Processing an error");
          reject([]);
        }
    );
  });
}

window.plotChart = function(data, chartType, divId, options, project_data = {}) {
  switch(chartType) {
    case 'pie_chart':
      plotPieChart(data.converted_data, divId, options);
      break;
    case 'sentiment_chart':
      plotColumnWithGroupedCategoriesChart(data.converted_data, divId, options);
      break;
    case 'column_chart':
      plotColumnChart(data.converted_data, divId, options);
      break;
    case 'column_sentiment_chart':
      plotColumnChart(data.converted_data, divId, options);
      break;
    case 'bar_chart':
      plotBarChart(data.converted_data, divId, options);
      break;
    case 'funnel_chart':
      plotFunnelChart(data.converted_data, divId, options);
      break;
    case 'line_chart':
      if (options['is_custom'] != undefined && options['is_custom']) {
        trendDataConverter(data, project_data, divId, options);
      } else {
        plotLineChart(data.converted_data, divId, options);
      }
      break;
    case 'sparkline_chart':
      plotSparklineChart(data.converted_data, divId, options);
      break;
    case 'word_cloud':
      plotWordCloud(data.converted_data, divId, options);
      break;
    case 'stacked_bar':
      plotStackedBarChart(data, options);
      break;
    case 'box_plot':
      plotBoxPlotChart(data.converted_data, divId, options);
      break;
    case 'sunburst_chart':
      plotSunburstChart(data.converted_data, divId, options);
      break;
    case 'kpi_change_chart':
      plotKpiChart(data.converted_data, divId, options);
      break;
    case 'kpi_percentage_chart':
      plotKpiChart(data.converted_data, divId, options);
      break;
    case 'nested_table_chart':
      plotNestedTableChart(data.converted_data, divId, options);
      break;
    case 'plain_table_chart':
      plotPlainTableChart(data.converted_data, divId, options);
      break;
    default:
      return [];
  }
}

window.plotNestedTableChart = function(data, divId, options) {
  var tableElement = jQuery("#" + divId);
  var headElement = tableElement.find("thead")
  var bodyElement = tableElement.find("tbody")
  headElement.append('<th style="width: 20%;">Dimension</th><th style="width: 10%;">Score</th><th style="width: 10%;">Score in %</th><th style="width: 60%;">Subcategories</th>')
  var newRows = ''
  $.each(data['values'], function(key, valueArray){
    newRows += '<tr>'
    newRows += '<td>'+ key +'</td>'
    newRows += '<td>'+ valueArray[0]['level1_score'] +'</td>'
    newRows += '<td>'+ valueArray[0]['level1_percentage'] +'</td>'
    newRows += '<td>'
    newRows += '<table class="table-bordered">'
    newRows += '<thead style="background: linear-gradient(to right, #03428a, #041528);color: white;"><tr>'
    newRows += '<th>Dimension</th><th style="width: 30%;">Score</th><th style="width: 30%;">Score in %</th>'
    newRows += '<tbody>'
    console.log("==============valueArray==============")
    console.log(valueArray)
    console.log("==============valueArray==============")
    valueArray.forEach(function(element) {
      if(element['level2'] != null){
        newRows += '<tr>'
        newRows += '<td>' + element['level2'] + '</td>'
        newRows += '<td>' + element['level2_score'] + '</td>'
        newRows += '<td>' + element['level2_percentage'] + '</td>'
        newRows += '</tr>'
      }
    });
    newRows += '</tbody>'
    newRows += '</tr></thead>'
    newRows += '</table>'
    newRows += '</td>'
  });
  bodyElement.append(newRows)
}

window.plotPlainTableChart = function(data, divId, options) {
  var definedTable = $('#' + divId)
  if(definedTable.length > 0){
    var tableElement = $('#' + divId)
  }else{
    var tableElement = jQuery("#" + divId);
  }
  var headElement = tableElement.find("thead")
  headElement.html('');
  var bodyElement = tableElement.find("tbody")
  bodyElement.html('');
  headerData = ''
  if (data.columns != undefined) {
    data.columns.forEach((element) =>
      headerData += '<th>' + element + '</th>'
    )
  }
  console.log(headerData)
  headElement.append(headerData)
  newRows = ''
  if (data.rows != undefined) {
    data.rows.forEach(function(element) {
      newRows += '<tr>'
      element.forEach(function(value){
        newRows += '<td>' + value + '</td>'
      })
      newRows += '</tr>'
    });
  }
  bodyElement.append(newRows)
}

window.plotKpiChart = function(data, divId, options) {
  if(options['show_percentage'] == true) {
    $("#" + divId + " .chart-text__top").text(data[0] || "Hello Percentage")
    $("#" + divId + " .chart-text__number").text(data[2] || 100)
  }
  else{
    $("#" + divId + " .chart-text__top").text(data[0] || "Hello")
    $("#" + divId + " .chart-text__number").text(data[1] || 100)
  }
}

window.plotStackedBarChart = function(data, options) {
  if( options['type'] == 'category' || options['type'] == 'question' ) {
    var chartData = {};
    var divId = divIdFromOptionType(options['type']);
    for (let element of data.all_rows) {
      if(!chartData[element[1]])
        chartData[element[1]] = {};

      chartData[element[1]][element[0]] = element[2];
    }
    Object.entries(chartData).forEach(([key, value]) => {
      Object.keys(value).forEach((inner_key) => {
        if(options['type'] == 'category') {
          var divId = key + '_category_sparkline_' + inner_key;
        } else {
          let mapping = JSON.parse(options['mapping']);
          var divId = mapping[key] + '_' + key + '_question_sparkline_' + inner_key;
        }
        if(options['column']){
          const type = (options['column'] == 'column_0') ? 'current' : 'target';
          divId = type + "_" + divId;
        }
        $("#" + divId).text(value[inner_key].toFixed(2) + "%");
      });
    });
  }else if( options['type'] == 'subcategory' ) {
    var chartData = {};
    for (let element of data.all_rows) {
      if(!chartData[element[1]])
        chartData[element[1]] = {};
      if(!chartData[element[1]][element[2]])
        chartData[element[1]][element[2]] = {};

      chartData[element[1]][element[2]][element[0]] = element[3];
    }
    Object.entries(chartData).forEach(([key, value]) => {
      Object.entries(value).forEach(([key1, value1]) => {
        Object.keys(value1).forEach((inner_key) => {
          var divId =  key1 + '_' + key + '_sub_category_sparkline_' + inner_key;
          $("#" + divId).text(value1[inner_key].toFixed(2) + "%");
        });
      });
    });
  }

}

window.divIdFromOptionType = function(type) {
  if(type == 'category')
    return "_category_sparkline_";
  else
    return "_question_sparkline_";
}

window.commonDataConverter = function(data) {
  var chartData = [];
  data.rows.forEach((element) =>
    chartData.push([element.name, element.value])
  );
  return chartData;
}

window.convertToNonChartData = function(data) {
  var chartData = {};
  if (data.columns.length != 0 && data.rows.length != 0) {
    chartData[data.columns[0].name] = data.rows[0].name;
    chartData[data.columns[1].name] = data.rows[0].value;
  }
  return chartData;
}

window.mapRowsToColumns = function(data) {
  var chartData = [];
  Object.entries(data.all_rows).forEach(([key, value]) => {
    var obj = {}
    Object.entries(data.columns).forEach(([k, v]) => {
      obj[v.name] = value[k]
    })
    chartData.push(obj);
  });
  return chartData;
}

// Trend chart by time,by category, questions and overall score(weighted average)
window.trendDataConverter = function(data, project_data, divId, options, chartType = 'line_chart') {
  if(project_data['multiline_trend']){
    return multiLineTrendDataConverter(data, project_data, divId, options);
  }
  var chartData = []
  var data_json = {}
  for (let element of data.all_rows) {
    data = {}
    if(project_data['dimension'] == 'overall'){
      chartData.push([element[0], element[1]])
    }else{
      if(chartData.filter(e => e.name === project_data[element[0].toString()]).length > 0){
        data[element[1]] = element[2]
        var object = chartData.find(obj => obj.name === project_data[element[0].toString()])
        Object.assign(object.data, {[element[1]]: element[2]})
      }else{
        data[element[1]] = element[2]
        data_json = {
          name: project_data[element[0].toString()],
          data: data
        }
        chartData.push(data_json)
      }
    }
  }

  if(chartType == 'sparkline_chart') {
    plotSparklineChart(chartData, divId, options);
  } else {
    // if(project_data['dimension'] == 'overall' || typeof(project_data['dimension']) == "undefined") {
      plotLineChart(chartData, divId, options);
    // } else {
      // prophetDataPredictions(chartData, divId, options);
    // }
  }
}

window.prophetDataPredictions = async function(data, divId, options) {
  const [resultData] = await Promise.all([prophetApiCall({series: data})]);

  var data = resultData.chart_data;

  options.stringValues = true;
  plotLineChart(data, divId, options);
}

window.multiLineTrendDataConverter = function(data, project_data, divId, options) {
  var chartData = [];

  if(project_data['dimension'] == 'overall'){
    var currentValues = {}, targetValues = {};
    var indexVal = null, targetVal = null, dataVal = null;
    project_data['dimension'] == 'overall' ? ( indexVal=1, targetVal=0, dataVal=2 ) : ( indexVal=2, targetVal=1, dataVal=3 )

    for (let element of data.all_rows) {
      element[indexVal] == 'column_0' ? (currentValues[element[targetVal]] = element[dataVal]) : (targetValues[element[targetVal]] = element[dataVal]);
    }

    chartData.push({ name: 'Current', data: currentValues });
    chartData.push({ name: 'Target', data: targetValues });
  } else {
    var dimension = project_data['dimension'] == 'question' ? 'master_ques_id' : 'master_cat_id';
    var grouppedData = mapRowsToColumns(data).reduce((arr, obj) => {
      arr[obj[dimension]] = [...arr[obj[dimension]] || [], obj];
      return arr;
    }, {});
    Object.entries(grouppedData).forEach(([key, values]) => {
      var categoryName = project_data[key.toString()] || key, currentValues = {}, targetValues = {};
      categoryName = categoryName.length > 10 ? categoryName.substr(0, 15) + '...' : categoryName;
      Object.entries(values).forEach(([k, v]) => {
        var obj = v.target_type == 'column_0' ? currentValues : targetValues;
        obj[v.timeline] = v.weighted_avg;
      })
      chartData.push({ name: 'Current - ' + categoryName, data: currentValues });
      chartData.push({ name: 'Target - ' + categoryName, data: targetValues });
    });
  }
  plotLineChart(chartData, divId, options);
}

window.plotSunburstChart = function(chartData, divId, options) {
  new Chartkick.SunBurstChart(divId, chartData, options);
}

window.plotPieChart = function(chartData, divId, options) {
  new Chartkick.PieChart(divId, chartData, options);
}

window.plotBarChart = function(chartData, divId, options) {
  new Chartkick.BarChart(divId, chartData, options);
}

window.plotBubbleChart = function(chartData, divId, options) {
  new Chartkick.BubbleChart2(divId, chartData, options);
}

window.plotColumnChart = function(chartData, divId, options) {
  new Chartkick.ColumnChart(divId, chartData, options);
}

window.plotFunnelChart = function(chartData, divId, options) {
  new Chartkick.FunnelChart(divId, chartData, options);
}

window.plotLineChart = function(chartData, divId, options) {
  new Chartkick.LineChart(divId, chartData, options);
}

window.plotWordCloud = function(chartData, divId, options) {
  new Chartkick.WordCloud(divId, chartData, options);
  // new Chartkick.WordCloud(divId,[["Blueberry",44],["Strawberry",23],["Banana",22],["Apple",21],["Grape",13]],options);
}

window.plotSparklineChart = function(chartData, divId, options) {
  if(divId == 'category_sparkline_' || divId == 'sub_category_sparkline_' || divId == 'question_sparkline_'){
    $.each(chartData, function( index, value ) {
      new Chartkick.SparklineChart(divId + value['name'], [value], options);
    });
  }else{
    new Chartkick.SparklineChart(divId, chartData, options);
  }
}

window.plotColumnWithGroupedCategoriesChart = function(chartData, divId, options) {
  options.categories = [chartData.categories]
  new Chartkick.ColumnWithGroupedCategoriesChart(divId, chartData.series_data, options);
}

window.plotBoxPlotChart = function(converted_data, divId, options) {
  // Example: ["Pioneering", "Energizing", "Affirming"]
  options.categories = converted_data[0]
  // Example: [[3.0, 3.75, 4.5, 4.75, 5.0], [2.0, 3.5, 5.0, 5.0, 5.0], [2.0, 3.5, 5.0, 5.0, 5.0]]
  var chartData = converted_data[1]
  // new Chartkick.BoxPlot('box-plot-chart', chartData, {categories:converted_data[0]});
  new Chartkick.BoxPlot(divId, chartData, options);
  // new Chartkick.BoxPlot(divId, [[3.0, 3.75, 4.5, 4.75, 5.0], [2.0, 3.5, 5.0, 5.0, 5.0], [2.0, 3.5, 5.0, 5.0, 5.0]], { download: true, name : 'observations', title : 'Box Plot', xTitle : 'X-Axis', yTitle: 'Y-Axis', categories: ["Pioneering", "Energizing", "Affirming"] });
}

window.humanize = function(str) {
  return str
      .replace(/^[\s_]+|[\s_]+$/g, '')
      .replace(/[_\s]+/g, ' ')
      .replace(/^[a-z]/, function(m) { return m.toUpperCase(); });
}

//6 levels of brightness from 0 to 5, 0 being the darkest
// window.getRandomColors = function(brightness, arr_lenght){
//   var color_arr = [];
//   for (let i = 0; i < arr_lenght; i++) {
//     var rgb = [Math.random() * 256, Math.random() * 256, Math.random() * 256];
//     var mix = [brightness*51, brightness*51, brightness*51]; //51 => 255/5
//     var mixedrgb = [rgb[0] + mix[0], rgb[1] + mix[1], rgb[2] + mix[2]].map(function(x){ return Math.round(x/2.0)})
//     color_arr.push("rgba(" + mixedrgb.join(",") + ")");
//   }
//   return color_arr;
// }

window.fetchTimeLineQuery = function(timeType) {
  switch(timeType) {
    case 'daily':
      return 'DATE(submission_answers.created_at)'
    case 'weekly':
      return 'CONCAT(EXTRACT(WEEK FROM submission_answers.created_at), " - ", EXTRACT(YEAR FROM submission_answers.created_at))'
    case 'monthly':
      return 'CONCAT(EXTRACT(MONTH FROM submission_answers.created_at), " - ",EXTRACT(YEAR FROM submission_answers.created_at))'
    case 'yearly':
      return 'EXTRACT(YEAR FROM submission_answers.created_at)'
    default:
      return [];
  }
}

window.getColumnChartData = function(row_data, category_data) {
  var newChartData = [];

  Object.entries(row_data).forEach(([key, value]) => {
    newChartData.push([category_data[value.master_cat_id].name, value.weighted_avg])
  });

  return newChartData;
}

window.fetchCatAndQues = function(dropdownVal, stamp, projectId){
  if(dropdownVal != 'overall'){
    var data = { id: projectId, data_value: dropdownVal }
    $.ajax({
      dataType: "json",
      cache: false,
      url: '/get_categories_or_questions',
      data: data,
      timeout: 5000,
      error: function(XMLHttpRequest, errorTextStatus, error) {
        alert("Error: " + errorTextStatus + " ;" + error);
      },
      success: function(data) {
        $("#trend-chart-by-value-"+stamp).css("display", "block");
        $("select#trend-chart-by-value-" + stamp + " option").remove();
        $("#trend-chart-by-value-"+stamp).select2("val", "");
        $('#trend-chart-by-value-'+stamp).select2({
          placeholder: "Select " + dropdownVal
        });
        $.each(data, function(i, value) {
          var row = "<option value=\"" + value[0] + "\">" + value[1] + "</option>";
          $(row).appendTo("select#trend-chart-by-value-" + stamp);
        });
      }
    });
  }
}

window.getSchemaList = function(dropdownVal, stamp){
  var data = { data_source: dropdownVal }
  if ($('[data-field_stamp="schema_name_'+ stamp +'"]').length > 0) {
    $.ajax({
      dataType: "json",
      cache: false,
      url: '/get_schema_list',
      data: data,
      timeout: 9000,
      error: function(XMLHttpRequest, errorTextStatus, error) {
        console.log("Error: " + errorTextStatus + " ;" + error);
      },
      success: function(data) {
        $('select[data-field_stamp="schema_name_' + stamp +'"] option').remove();
        $('[data-field_stamp="schema_name_'+ stamp +'"]').select2("val", "");
        $('[data-field_stamp="schema_name_'+ stamp +'"]').select2({
          placeholder: "Select " + dropdownVal
        });
        $.each(data, function(i, value) {
          var row = "<option value=\"" + value + "\">" + value + "</option>";
          $(row).appendTo('select[data-field_stamp="schema_name_'+ stamp +'"]');
        });
      }
    });
  }
}

// Box plot calculation
window.numSort = function(a,b) {
  return a - b;
}

// Box plot calculation
window.getPercentile = function(data, percentile) {
  data.sort(numSort);
  var index = (percentile/100) * data.length;
  var result;
  if (Math.floor(index) == index) {
    result = (data[(index-1)] + data[index])/2;
  }
  else {
    result = data[Math.floor(index)];
  }
  return result;
}

// Box plot calculation
window.getBoxValues = function(data) {
  var boxValues = {};
  boxValues.low = Math.min.apply(Math,data);
  boxValues.q1 = getPercentile(data, 25);
  boxValues.median = getPercentile(data, 50);
  boxValues.q3 = getPercentile(data, 75);
  boxValues.high = Math.max.apply(Math,data);

  return [boxValues.low, boxValues.q1, boxValues.median, boxValues.q3, boxValues.high];
}

// Box plot calculation
window.formattedNumbers = function(num) {
  if (Number.isInteger(num)) {
    return num.toString();
  } else if (typeof num === 'number') {
    return num.toFixed(2);
  } else {
    return num.toString();
  }
}
