// html stackbar
const vl = document.querySelectorAll('.html__stackbar--value');
vl.forEach(function(i) {
	let text = i.innerText;
	i.parentNode.style.width = text;
});

const slider = document.querySelector('.click-to-drag');
let isDown = false;
let startX;
let scrollLeft;

if (slider) {
  slider.addEventListener('mousedown', (e) => {
    isDown = true;
    slider.classList.add('drag-active');
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  });
  slider.addEventListener('mouseleave', () => {
    isDown = false;
    slider.classList.remove('drag-active');
  });
  slider.addEventListener('mouseup', () => {
    isDown = false;
    slider.classList.remove('drag-active');
  });
  slider.addEventListener('mousemove', (e) => {
    if(!isDown) return;
    e.preventDefault();
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startX) * 3; //scroll-fast
    slider.scrollLeft = scrollLeft - walk;
    console.log(walk);
  });
}

function changeHeight() {
  document.getElementById('cat_nav').style.height = "max-content";
};

function changeHeightTrd() {
  document.getElementById('trd_nav').style.height = "max-content";
};


window.toggle = function(ele, dom_id) {
  var cont = document.getElementById(dom_id);
  if (cont.style.display == 'block') {
    cont.style.display = 'none';

    document.getElementById(ele.id).value = 'Show DIV';
  }
  else {
    cont.style.display = 'block';
    document.getElementById(ele.id).value = 'Hide DIV';
  }
}

// for adapter sample url show
window.showDatabaseSamples = function(a) {
  $('div[class^="sample-url"]').not('.hidden').addClass('hidden');
  document.getElementById(a.value + '-url').classList.remove("hidden")
}

window.generateRandomNumber = function() {
  var array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return array[0] % 10000000000;
}
